import React from "react"
import { Routes, Route } from "react-router-dom"

import { motion } from "framer-motion"
import ActualHomePage from "./ActualHomePage"
import TheScience from "./TheScienceComp/TheScience"
import Diseaseportfolio from "../ScreenRoutes/DiseasePortfolioComp/Diseaseportfolio"
import ContactOurExperts from "./ExpertContactComp/ContactOurExperts"
import Team2 from "../ScreenRoutes/TeamComp/Team2"
import LoginPageVer2 from "../ScreenRoutes/LoginPageComp/LoginPageVer2"
import DiseaseDetailsPage from "../ScreenRoutes/DiseasePortfolioComp/DiseaseDetailsPage"
import ScrollToTopPage from "./ScrollToTopPage"
import Careers from "../ScreenRoutes/CareersComp/Careers"
import JobSearchPage from "../ScreenRoutes/JobSearchPage/JobSearchPage"
import PrivacyPolicy from "../ScreenRoutes/PrivacyPolicy/PrivacyPolicy"
import OurVision from "../ScreenRoutes/OurVision/OurVision"
import OurValues from "../ScreenRoutes/OurValues/OurValues"
import OurInnovation from "../ScreenRoutes/OurInnovation/OurInnovation"
import OurStrategy from "../ScreenRoutes/OurStrategy/OurStrategy"
import TermsOfService from "../ScreenRoutes/TermsOfService/TermsOfService"
import Newsletter from "../ScreenRoutes/Newsletter/Newsletter"
import ErrorPage from "../ScreenRoutes/ErrorPageComp/ErrorPage"
import PageLayout from "./PageLayout"

const homeContainer = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      type: "tween",
      delay: 0.2,
      duration: 1,
      ease: "easeOut",
    },
  },
}

const locations = [
  "milestones",
  "portfolio",
  "detailspage",
  "team",
  "careers",
  "jobsearch",
  "login",
  "contactus",
  "privacypolicy",
  "vision",
  "innovation",
  "values",
  "strategy",
  "termsofservice",
  "newsletter",
]

function HomePage() {
  return (
    <motion.div variants={homeContainer} initial="hidden" animate="visible">
      <ScrollToTopPage />
      <Routes>
        <Route element={<PageLayout />}>
          <Route exact path="/" element={<ActualHomePage />} />
          <Route path="thescience" element={<TheScience />} />
          <Route path="portfolio" element={<Diseaseportfolio />} />
          <Route path="detailspage/:id" element={<DiseaseDetailsPage />} />
          <Route path="team" element={<Team2 />} />
          <Route path="careers" element={<Careers />} />
          <Route path="jobsearch" element={<JobSearchPage />} />
          <Route path="login" element={<LoginPageVer2 />} />
          <Route path="contactus" element={<ContactOurExperts />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="vision" element={<OurVision />} />
          <Route path="innovation" element={<OurInnovation />} />
          <Route path="values" element={<OurValues />} />
          <Route path="strategy" element={<OurStrategy />} />
          <Route path="termsofservice" element={<TermsOfService />} />
          <Route path="newsletter" element={<Newsletter />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </motion.div>
  )
}

export default HomePage
