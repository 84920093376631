import React, { useEffect, useRef, useState } from "react"
import "./index.css"
import botImageOpen from "./Assets/eyes-open.png"
import { FaDotCircle } from "react-icons/fa"
import { MdSend } from "react-icons/md"
import BotText from "./BotText"
import UserText from "./UserText"

import ChatBotResponses from "./ChatBotResponses"

function ChatBot() {
  const [chat, setChat] = useState([ChatBotResponses[0]])
  const [online, setOnline] = useState(true)
  const [textInput, setUserText] = useState("")

  const handleChangeText = (e) => {
    setUserText(e.target.value)
  }

  const handleClick = (button) => {
    let updatedOption = ChatBotResponses.filter((item) => button.id === item.id)
    let userText = button.option
    setOnline(false)
    setChat([
      ...chat,
      {
        id: chat.length + 1,
        text: userText,
      },
    ])
    setTimeout(() => {
      setOnline(true)
      setChat([
        ...chat,
        {
          id: chat.length + 1,
          text: userText,
        },
        updatedOption[0],
      ])
    }, 2000)
  }

  const handleSubmit = (button) => {
    let newChat = [
      ...chat,
      {
        id: chat.length + 1,
        user: "user",
        text: textInput,
      },
    ]
    setChat(newChat)
    setUserText("")
  }

  let chatConvo = chat.map((item) => {
    if (item.user === "bot") {
      return (
        <div className="bot-response-convo">
          <img src={botImageOpen} alt="bot-icon" className="bot-image-convo" />
          <BotText selection={handleClick} item={item} />
        </div>
      )
    } else {
      return <UserText item={item} />
    }
  })

  const scrollToBottom = useRef(null)

  useEffect(() => {
    scrollToBottom.current?.scrollIntoView()
  }, [chat])

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">
        <img src={botImageOpen} alt="botImage" className="bot-image" />
        <div>
          <p className="chatbot-title">Veda</p>
          <div className="status-block">
            <FaDotCircle size={12} color="green" />
            <p className="online-status">{online ? "Online" : "Typing..."}</p>
          </div>
        </div>
      </div>
      <div className="chatbot-text-area" id="convo-container">
        {chatConvo}
        <div ref={scrollToBottom} />
      </div>
      <div className="chatbot-footer">
        <input
          type="text"
          value={textInput}
          placeholder="Type your message here"
          className="chatbot-input-box"
          onChange={(e) => handleChangeText(e)}
        />
        {/* <button className="chatbot-submit-button" onClick={handleSubmit}>Submit</button> */}
        <MdSend onClick={handleSubmit} className="chat-bot-submit-icon" />
      </div>
    </div>
  )
}

export default ChatBot
