import React from "react"
import "./OurValues.css"

import valuesBackground from "./Assets/values-background.jpg"
import image1 from "./Assets/image1.jpg"
import image2 from "./Assets/image2.jpg"
import image3 from "./Assets/image3.jpg"
import AppHeader from "../../ReusableComponents/AppHeader"
import { Container } from "@mui/system"

function OurValues() {
  return (
    <div className="values-container">
      <AppHeader
        image={valuesBackground}
        title="Values"
        subTitle="We value lives and time of everyone around us!"
      />
      <div>
        <div className="container-values">
          <div className="values-section">
            <div className="values-text-content">
              <p className="values-subheading">Aiding needs of our clientele</p>
              <p className="values-item">
                We provide quality homecare at low costs
              </p>
              <p className="values-item">
                Personalized check-ups at your own pace
              </p>
              <p className="values-item">
                Expertise in diagnosis with patient doctor follow ups
              </p>
            </div>
            <img src={image1} className="values-image" />
          </div>
          <div className="values-section">
            <img src={image3} className="values-image second-item" />
            <div className="values-text-content">
              <p className="values-subheading">Reflecting potentiality</p>
              <p className="values-item">
                A blend of finest equipments and latest technology
              </p>
              <p className="values-item">
                Leading testaments having best user-experience results
              </p>
              <p className="values-item">
                Accomplished outcomes aligning to the needs of every individual
              </p>
            </div>
            <img src={image3} className="values-image second-item-2" />
          </div>
          <div className="values-section">
            <div className="values-text-content">
              <p className="values-subheading">Inspire and innovate</p>
              <p className="values-item">
                Multimodal data collection and continuous analysis - the first of its kind
              </p>
              <p className="values-item">
                Personalized therapy
              </p>
              {/* <p className="values-item">
                Expertized diagnosis with patient doctor follow ups
              </p> */}
            </div>
            <img src={image2} className="values-image" />
          </div>
        </div>
        <div className="values-final-note">
          <p>
            Catering to a growing percentage of people suffering from
            neuro-health complications, we thrive to give back more with
            constant innovations and by responding in the most efficient manner.
          </p>
          <p>
            Our healthcare wishes to bridge the gap between healthcare and
            mankind.
          </p>
        </div>
      </div>
    </div>
  )
}

export default OurValues
