import React from "react"
import "./PrivacyPolicy.css"

import privacyBackground from "./Assets/privacy-background.jpg"

import AppHeader from "../../ReusableComponents/AppHeader"

function PrivacyPolicy() {
  return (
    <div className="policy-container">
      <AppHeader
        image={privacyBackground}
        title="Privacy Policy"
        // subTitle="lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
      />
      <div className="privacy-policy-content">
        <p className="privacy-disclaimer">
          Disclaimer: The Company can modify this Policy unilaterally at any
          time without notice. Modification may be necessary, among other
          reasons, to maintain compliance with applicable laws and regulations
          or accommodate organizational changes within the Company.
        </p>
        <p className="privacy-firstNote">
          We understand that the privacy of your personal information is
          important and hence we would to keep you informed and empowered with
          respect to your privacy on{" "}
          <a href="https://www.nirveshenterprises.in/">Nirvesh Enterprises</a>.
          This Privacy Policy applies to website, and its related services
          operated and rendered by Nirvesh Enterprises Private Limited. This
          Policy describes use and sharing of personally identifiable
          information submitted by its registered users receiving the services,
          such information being related to you or other users downloading,
          accessing or using the platform. The services offered adhere to all
          the laws, rules, regulations and policies with regard to the
          collection, use, disclosure, retention and protection of your
          information including Personal Data, that the company is likely to
          have access to as a result of your use of the Platform.
        </p>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">
            1. What the Company Collects from You
          </p>
          <ul>
            <li>
              Any data about you, by which you, as a person can be identified,
              including without limitation, your name, gender, business name,
              address, email address, date of birth, date of incorporation, any
              identity proof number, phone number etc., to which Company has or
              is likely to have access by virtue of your consensual submission.
              Personal Data shall also include the information of such third
              parties that Company may have access to during the course of
              providing the Services to you. Company may also collect recurring
              information of your geographic locations on a continuous basis.
              Company collects such Personal Data from you because of your
              voluntary submission of such information in order to enjoy the
              Services.
            </li>
            <li>
              Information collected from website submissions will be used for
              Company’s internal business purposes, including its
              investigations, sales, and marketing. We may use IP addresses for
              purposes such as calculating usage levels, diagnosing server
              problems and administering the services. We may also derive your
              approximate location from your IP address. The website may include
              options for visitors to submit information to Company, for
              example, while requesting information about a product/service,
              downloading an article, etc. Such forms may provide the option of
              submitting personal information, such as your name, job title,
              company, email address, or phone number. In addition, when
              submitting information on a website, your submission will be
              associated with the IP address you are then using, which is
              automatically assigned to your computer by your Internet Service
              Provider. If you are using one of the market leading browsers and
              are uncomfortable accepting cookies from our website, you may
              erase and/or disable the acceptance of cookies in your browser’s
              administrative tools. Disabling your browser in this manner may
              result in a compromised experience of{" "}
              <a href="https://www.nirveshenterprises.in/">
                Nirvesh Enterprises
              </a>{" "}
              or other websites you regularly visit.
            </li>
            <li>
              Company may also collect such other anonymous data that is not
              associated with or linked to any Personal Data and which, by
              itself, does not permit the identification of any individual User.
              Anonymous Data includes without limitation, unique identifiers of
              the device on which Company is installed, such as VPN, information
              of Wi-Fi connectivity and such other information. Company may also
              store this unique identifier, at many times and create a profile
              for you based on such Anonymous Data.
            </li>
            <li>
              In addition, Company shall automatically collect certain other
              information, including but not limited to usernames, passwords,
              and other security and usage related information, whenever you
              visit or utilize the Services such as Log Data, Analytical Code,
              Time Stamp, Geo Stamp, IP address, version and identification
              number of the App and device, browser type, browser language, the
              date and time of your request, your profile, websites visited by
              you, search terms used, your mobile carrier data, platform type,
              number of clicks, phone number, requested status of other Users
              and various status information with regard to the Services
              provided through{" "}
              <a href="https://www.nirveshenterprises.in/">
                Nirvesh Enterprises
              </a>{" "}
              . Such Usage Information collected is not associated with any
              Personal Data and is only tagged to the unique identifier for a
              particular device.
            </li>
            <li>
              Company uses data collection devices such as “cookies” on your
              device through which{" "}
              <a href="https://www.nirveshenterprises.in/">
                Nirvesh Enterprises
              </a>{" "}
              is accessed that assist{" "}
              <p style={{ margin: "10px 0px" }}>
                (a) in providing Services and{" "}
              </p>{" "}
              <p style={{ margin: "10px 0px" }}>
                (b) to allow you to enter your password less frequently during a
                session.
              </p>{" "}
              Most cookies are “session cookies”. A Session cookie is temporary
              and is automatically deleted from your device at the end of a
              session. You are always at an option to decline{" "}
              <a href="https://www.nirveshenterprises.in/">
                Nirvesh Enterprises
              </a>
              ’s cookies if the application permits, although in that case you
              may not be able to use certain features on{" "}
              <a href="https://www.nirveshenterprises.in/">
                Nirvesh Enterprises
              </a>{" "}
              and you may be required to re-enter your password more frequently
              during a session.
            </li>
          </ul>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">
            2. How the information collected is being used.
          </p>
          <ul>
            <li>
              Company may use the Personal Data and other information collected
              as mentioned above for any of the following purposes:
              <ul>
                <li>to effectively provide the Services</li>
                <li>
                  for the purposes of software verification or administering
                  software upgrades
                </li>
                <li>
                  to allow you to participate in surveys regarding Services
                </li>
                <li>
                  to maintain the quality of the Services and provide general
                  statistics regarding their use
                </li>
                <li>
                  to deliver Services, including email notifications,
                  confirmations, technical notices, updates, security alerts,
                  and support and administrative messages that you may request
                </li>
                <li>archival or backup purposes</li>
                <li>
                  to alert you about updated information and other such new or
                  updated services that may be offered by other Users
                </li>
                <li>
                  to allow access to exclusive features or functionality of{" "}
                  <a href="https://www.nirveshenterprises.in/">
                    Nirvesh Enterprises
                  </a>{" "}
                </li>
                <li>
                  to launch and/or open other mobile applications installed on
                  your device based on functionality of{" "}
                  <a href="https://www.nirveshenterprises.in/">
                    Nirvesh Enterprises
                  </a>{" "}
                </li>
                <li>
                  to resolve disputes, collect fees and troubleshoot problems.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">
            3. Payment and billing information.
          </p>
          <p className="privacy-para">
            Company may collect your billing name, billing address and payment
            method when you pay the Charges as defined in the Terms of Use
            available here on the Website. Please note that{" "}
            <a href="https://www.nirveshenterprises.in/">Nirvesh Enterprises</a>{" "}
            online payment partner would collect your credit/debit card number
            or credit/debit card expiry date or other details pertaining to your
            credit/debit card.
          </p>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">
            4. Sharing of the collected information.
          </p>
          <ul>
            <li>
              Company may share the Personal Data received from you:
              <ul>
                <li>
                  With the Company’s affiliates in connection with matters
                  relating to the consumption of Services by you to help detect
                  and prevent identity theft, fraud and other potentially
                  illegal acts; correlated or multiple accounts to prevent abuse
                  of Services; and to facilitate joint or co-branded services
                  that you request, where such services are provided by more
                  than one corporate entity other than the Company; and
                </li>
                <li>
                  if required to do so by law or regulation; to its legal
                  counsel, law enforcement officers, governmental authorities
                  who have asserted their lawful authority to obtain the
                  information or where the Company has in good faith reasonable
                  grounds to believe that such disclosure is reasonably
                  necessary to enforce its Terms of Use or this Privacy Policy.
                </li>
              </ul>
            </li>
            <li>
              The Company shall not rent, sell, or otherwise provide Personal
              Data received from you to third parties without your consent,
              except as described in this Policy or as required by law. However,
              the Company and its affiliates reserve the right to share, sell
              and transfer some or all of the Personal Data to other business
              entities should the Company, or its assets, plan to merge with, or
              be acquired by that business entity.
            </li>
          </ul>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">
            5. Security of Personal Information
          </p>
          <ul>
            <li>
              The Company has implemented commercially reasonable physical,
              managerial, operational and technical security measures to protect
              the loss, misuse, alteration and security of the Personal Data
              received from you in the Company’s care. The Company’s security
              practices and procedures limit access to Personal Data on a
              need-to-know basis only. Such measures may include, where
              appropriate, the use of firewalls, secure server facilities,
              implementing proper access rights management systems and
              processes, careful selection of processors, standard
              authentication and encryption policies for its servers and other
              technically and commercially reasonable measures.
            </li>
            <li>
              The Company is committed to protecting the security of any
              Personal Data and uses reasonable efforts including a variety of
              security technologies and procedures to help protect such Personal
              Data from any unauthorized access, use or disclosure. The Company
              will take all reasonable measures in furtherance of the above
              commitment. However, the Company cannot guarantee that the
              Personal Data received from you and other information stored on
              the servers will be absolutely protected. Company’s systems and
              the communications networks through which you access{" "}
              <a href="https://www.nirveshenterprises.in/">
                Nirvesh Enterprises
              </a>{" "}
              may be subject to security breaches and failures due to
              circumstances beyond Company’s reasonable control. These may
              include computer "hacking", data theft or and physical theft among
              others. In such situations, the Company will try to rectify the
              problems as soon as possible.
            </li>
            <li>
              Any data destroyed shall be disposed of in a manner that protects
              the privacy of the Personal Data received by Company from you in
              an appropriate manner as per the industry standard practices and
              norms.
            </li>
            <li>
              The User hereby acknowledges and understands that all Personal
              Data provided in using the App is with their knowledgeable consent
              and at the User’s own risk.
            </li>
          </ul>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">6. Your Obligation.</p>
          <ul>
            <li>
              As a registered user of the Services, you have certain obligations
              to other users. Some of these obligations are imposed by
              Applicable Law and regulations, and others have become commonplace
              for Services as offered by Company:
              <ul>
                <li>
                  you must, at all times, abide by the terms and conditions of
                  the then prevailing Privacy Policy and Terms of Use. This
                  includes respecting all intellectual property rights that may
                  belong to third parties
                </li>
                <li>
                  you must not upload or insert any comments that may be deemed
                  to be injurious, violent, offensive, racist or xenophobic, or
                  community of users
                </li>
                <li>
                  You must keep your username and passwords confidential and not
                  share it with others and
                </li>
                <li>
                  All information given must be genuine and true to your best
                  knowledge. The Company shall not be held responsible in case
                  of any fraudulent information given at the time of
                  registration for using{" "}
                  <a href="https://www.nirveshenterprises.in/">
                    Nirvesh Enterprises
                  </a>{" "}
                </li>
                <li>
                  Any violation of the guidelines as mention in this Clause may
                  lead to the restriction, suspension or termination of your
                  account with Company, at the sole discretion of the Company.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">7. Governing Law</p>
          <p className="privacy-para">
            The usage of the Services is exclusively governed by the laws
            of India and the jurisdiction of the competent courts in Hyderabad.
            The Company expressly disclaims any implied warranties or
            liabilities imputed by the laws of any other jurisdiction.
          </p>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">8. Modifications</p>
          <p className="privacy-para">
            Any changes in this Policy by the Company shall be posted on the
            Website to reflect changes in its Privacy Policy so that you are
            always aware of what information Company collects, uses, and under
            what circumstances, if any, Company may disclose it. You should
            periodically review the abovementioned page for the latest
            information on Company’s privacy practices. Once posted, those
            changes are effective immediately. Continued access or use of{" "}
            <a href="https://www.nirveshenterprises.in/">Nirvesh Enterprises</a>{" "}
            constitutes your acceptance of the changes and the amended Policy.
          </p>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">9. Advertisements</p>
          <p className="privacy-para">
            Company may use third-party advertising companies to serve
            advertisements when you contact it. These companies may use
            information (not including name, address, email address, or
            telephone number of the user) about your visits to{" "}
            <a href="https://www.nirveshenterprises.in/">Nirvesh Enterprises</a>{" "}
            and other websites in order to provide advertisements about goods
            and services of interest to you.
          </p>
        </div>
        <div className="privacy-paragraph">
          <p className="privacy-parah-heading">10. Questions/Grievances</p>
          <p className="privacy-para">
            Any grievances or questions regarding this statement should be
            directed to the e-mail address present on the company’s website.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
