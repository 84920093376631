import React, { useEffect } from "react"
import "./index.css"

import { motion } from "framer-motion"

function FeatureCard({ item }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }}
      className="feature-card"
      key={item.id}
    >
      <span className="material-symbols-rounded feature-icon">{item.icon}</span>
      <p className="feature-description">{item.text}</p>
    </motion.div>
  )
}

export default FeatureCard
