import React from "react"
import "./OurStrategy.css"
import { Container } from "@mui/system"
import imageglobe from "./Assets/globe.jpg"
import tech from "./Assets/tech.jpg"

import strategyBackground from "./Assets/clock.jpg"

import AppHeader from "../../ReusableComponents/AppHeader"
import AppSubHeading from "../../ReusableComponents/AppSubHeading"
import colors from "../../DefaultColors"

function OurStrategy() {
  return (
    <div className="strategy-container">
      <AppHeader
        image={strategyBackground}
        title="Outlook"
        subTitle="Our foresight is to outdo the existing technology and revolutionize it with Nirvesh's Technology"
      />
      <p
        className="innovation-final-note"
        style={{ width: "80%", margin: "0px auto" }}
      >
        Nirvesh enterprises believes in expanding its domain all across the
        world when it comes to treating medical problems that involve
        neurological disorders.
      </p>
      <Container>
        <div className="innovation-subcontainer">
          <p className="innovation-brief">
            By dominating the largest market of Neurotechnology, we aim to
            achieve substantial growth in the field of neurostimulation and
            arguably become the most suited company providing post operative
            brain health management. With the increasing need to cater to
            neurological disorders, the uses of EEG's and fNIRS have come much
            in demand, and we pool in the gap between the need to get treatments
            done without the hassle. By endorsing neuroheath at global level and
            reaching out to people by considering individual lives to be a
            crucial we strive on catering to the needs while making sure to bond
            on a personal level. Our professionalism is embedded with the idea
            to not just improve the physical and mental conditions but making
            living aspects as comfortable and normal as before the injury.
          </p>
          <div className="innovation-image-container">
            <img src={imageglobe} className="innovation-image" />
          </div>
        </div>
        <div className="innovation-subcontainer-left">
          <div className="innovation-image-container innovation-hide">
            <img src={tech} className="innovation-image innovation-image-1" />
          </div>
          <p className="innovation-brief">
            Our products have paved their way into the market by using cutting
            edge technology, wherein our experts have come up with a
            revolutionary idea to remodel the existing technology and replacing
            them with our products. The approach to diagnose post operative
            brain health complications by introducing devices coming at cost
            effective rates while providing the same benefits helps us stand out
            from our competitors. The potentiality of our devices is such that
            it would leave our consumers with no benefit of doubt, and they can
            get large-scale check-ups at a much easier and self-paced level.
          </p>
          <div className="innovation-image-container">
            <img src={tech} className="innovation-image-2" />
          </div>
        </div>
        <div>
          <p className="innovation-final-note">
            The key to sustain in the global market is by advancing in the
            sector and enhancing the use of technology at an easier level with
            self-paced technology to save the consumer the complexity of using a
            new device. Hence, we remove the benefit of doubt from the minds of
            our consumers in choosing our innovations. With the right strategy
            and idea we desire to lead our way into the Neuro-SciTech economy by
            blending in with the needs of our consumers to become the top-notch
            company to help them rebuild, regain and recover from brain health
            disorders.
          </p>
        </div>
      </Container>
    </div>
  )
}

export default OurStrategy
