import React, { useState } from "react"
import "./Footer.css"
import { Link } from "react-router-dom"
import { BsTwitter } from "react-icons/bs"
import { BsFacebook } from "react-icons/bs"
import { AiFillInstagram } from "react-icons/ai"
import { SiGmail, SiRedhatopenshift } from "react-icons/si"
import { BsLinkedin } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import axios from "axios"
import Alert from "@mui/material/Alert"

const whyUslinks = [
  {
    id: "1",
    link: "vision",
    text: "Our Vision",
  },
  {
    id: "2",
    link: "values",
    text: "Values",
  },
  {
    id: "3",
    link: "innovation",
    text: "Product",
  },
  {
    id: "4",
    link: "strategy",
    text: "Outlook",
  },
]
const listData = [
  {
    id: "1",
    link: "",
    text: "Home",
  },
  {
    id: "2",
    link: "thescience",
    text: "The Science",
  },
  {
    id: "3",
    link: "portfolio",
    text: "Portfolio",
  },
  {
    id: "4",
    link: "team",
    text: "Our Team",
  },
  {
    id: "5",
    link: "contactus",
    text: "Contact Us",
  },
  {
    id: "6",
    link: "login",
    text: "Synpasil Login",
  },
]
const usefulLinks = [
  {
    id: "1",
    link: "newsletter",
    text: "Newsletter",
  },
  {
    id: "2",
    link: "careers",
    text: "Careers",
  },
  {
    id: "3",
    link: "termsofservice",
    text: "Terms of Service",
  },
  {
    id: "4",
    link: "privacypolicy",
    text: "Privacy Policy",
  },
]
const ListItems = ({ item }) => {
  return (
    <Link to={`/${item.link}`} className="footer-item list-item">
      {item.text}
    </Link>
  )
}

function Footer() {
  const [alert, setAlert] = useState({
    isopen: false,
    message: "",
  })

  const handleClose = () => {
    setAlert({
      isopen: false,
      message: "",
    })
  }

  const SubmitEmail = (e) => {
    e.preventDefault()
    let emailid = document.getElementById("newsletter-input").value

    if (!emailid || !emailid.includes("@")) {
      setAlert({
        isopen: true,
        message: "Please enter valid Email Id",
      })
      setTimeout(() => {
        setAlert({
          isopen: false,
          message: "",
        })
      }, 3000)
      return
    }

    axios
      .post("http://13.126.94.16:8080/newsletter", { email: emailid })
      .then((response) => {
        document.getElementById("newsletter-input").value = ""
        setAlert({
          isopen: true,
          message: response.data.message,
        })
        setTimeout(() => {
          setAlert({
            isopen: false,
            message: "",
          })
        }, 3000)
      })
      .catch((error) => {
        setAlert({
          isopen: true,
          message: "Please enter valid Email Id",
        })
        setTimeout(() => {
          setAlert({
            isopen: false,
            message: "",
          })
        }, 3000)
      })
  }
  return (
    <div className="footer">
      <div className="footer-menu">
        <div className="useful-links">
          <p className="footer-heading useful-links-heading">Why Us?</p>
          <div className="footer-items-container">
            {whyUslinks.map((item) => ListItems({ item }))}
          </div>
        </div>
        <div className="useful-links">
          <p className="footer-heading useful-links-heading">Quick Links</p>
          <div className="footer-items-container">
            {listData.map((item) => ListItems({ item }))}
          </div>
        </div>
        <div className="useful-links">
          <p className="footer-heading useful-links-heading">Company</p>
          <div className="footer-items-container">
            {usefulLinks.map((item) => ListItems({ item }))}
          </div>
        </div>
        <div>
          <div className="newsletter-comp" style={{ position: "relative" }}>
            <div className="useful-links">
              <p className="footer-heading">Subscribe to Newsletter</p>
              <div className="newsletter-subscription">
                <input
                  type="text"
                  id="newsletter-input"
                  className="newsletter-input"
                  placeholder="Enter your email address"
                />
                <MdEmail size={32} className="footer-email-icon" />
                <div className="newsletter-icon">
                  <button
                    className="footer-subscribe-button"
                    onClick={SubmitEmail}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
            {alert.isopen && (
              <Alert
                severity="info"
                sx={{ maxWidth: "250px", margin: "20px auto", width: "100%" }}
              >
                {alert.message}
              </Alert>
            )}
          </div>
        </div>
      </div>
      <div className="footer-divider"></div>
      <div className="footer-copyright">
        <p className="footer-copyright-content">
          © Copyright Nirvesh Enterprises. All Rights Reserved 2021
        </p>
        <div className="social-networks">
          <p className="follow-us">Follow us on</p>
          <div>
            <a href="https://twitter.com" target="_blank">
              <BsTwitter className="social-media-icon" />
            </a>
            <a href="https://facebook.com" target="_blank">
              <BsFacebook className="social-media-icon" />
            </a>
            <a href="https://instagram.com" target="_blank">
              <AiFillInstagram className="social-media-icon" />
            </a>
            <a href="https://gmail.com" target="_blank">
              <SiGmail className="social-media-icon" />
            </a>
            <a href="https://linkedin.com" target="_blank">
              <BsLinkedin className="social-media-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
