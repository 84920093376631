import React from "react"
import { useNavigate } from "react-router-dom"
import "./ErrorPage.css"

function ErrorPage() {
  const navigation = useNavigate()

  function navigateToHome() {
    navigation("/")
  }
  return (
    <div className="error-page-container">
      <div className="error-content-container">
        <p className="error-page-message">Ooops!!! Guess You're Lost</p>
        <button onClick={navigateToHome} className="error-page-redirection">
          Back Home
        </button>
      </div>
    </div>
  )
}

export default ErrorPage
