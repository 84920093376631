import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import colors from "../../DefaultColors"
import AppTitle from "../../ReusableComponents/AppTitle"
import { useNavigate } from "react-router-dom"

import "./index.css"
import AppButtonRound from "../../ReusableComponents/AppButtonRound"
import AppSubHeading from "../../ReusableComponents/AppSubHeading"
import FeatureCard from "./FeatureCard"

const provide = [
  {
    id: "1",
    bgColor: colors.blue,
    icon: "favorite",
    text: "End-to-End cost effective Post-operative Brain health Management system",
  },
  {
    id: "2",
    bgColor: colors.blue,
    icon: "cognition",
    text: "Targeted cognitive status classification algorithm > 95% accuracy",
  },
  {
    id: "3",
    bgColor: colors.blue,
    icon: "health_and_safety",
    text: "Beneficial Interventions (Audio-Visual Training) to improve well-being",
  },
  {
    id: "4",
    bgColor: colors.blue,
    icon: "monitoring",
    text: "Multimodal data collection and continuous analysis",
  },
]

// Animation

const aboutComponent = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.6,
      ease: "easeIn",
    },
  },
}

function AboutUs() {
  const controls = useAnimation()
  const { ref, inView } = useInView({
    threshold: 0.3,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/innovation")
  }

  return (
    <motion.div
      className="about-us-container"
      id="ABOUT-US"
      ref={ref}
      variants={aboutComponent}
      initial="hidden"
      animate={controls}
    >
      <AppTitle title="About Us" />
      <AppSubHeading text="We are a team of experts having core expertise of 45+ persons' years specializing in the field of Neuroscience, Biomedical sciences and Embedded Engineering. " />
      <div className="about-us">
        <motion.div className="main-content">
          <div>
            <div className="we-provide">
              <div className="right-content">
                <p className="we-provide-subheading">What We Provide</p>
                <div className="features-cards">
                  {provide.map((item) => (
                    <FeatureCard item={item} />
                  ))}
                </div>
              </div>
            </div>
            <div className="final-content">
              <p className="about-us-final-content">
                With a crucial need to interchange the existing technology by
                introducing an evidence-based diagnostic technology as a
                complementary, we plan on introducing a multimodal approach and
                consolidating it with a holistic report identified by our
                innovation having better accuracy. Our mission and vision are to
                provide healthcare at a much broader level by redefining
                neurohealth and serving a unique solution for rehabilitation
                centres or such organizations looking for diagnosis and
                betterment of their patients.
              </p>
              <AppButtonRound
                text="Learn More"
                bgColor={colors.navy}
                border={colors.navy}
                afterClick={handleClick}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default AboutUs
