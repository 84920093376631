import React, { useEffect, useState, useRef } from "react"
import AppHeader from "../../ReusableComponents/AppHeader"
import contactUs from "./Assests/contact-us.jpg"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import "./ContactOurExpert.css"
import { TiLocation } from "react-icons/ti"
import { TfiEmail } from "react-icons/tfi"
import { RiContactsFill } from "react-icons/ri"
import axios from "axios"

// Animation

const cardComponent = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.5,
      ease: "easeIn",
    },
  },
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function ContactOurExperts() {
  const controls = useAnimation()
  const { ref, inView } = useInView({})

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  // Alertbox

  const [open, setOpen] = React.useState(false)
  const [disable, setDisable] = React.useState(false)
  const [text, settext] = React.useState(
    "Your Message was submitted successfully. Thank you!"
  )

  const [formDetails, setFormDetails] = useState({
    userName: "",
    emailId: "",
    subject: "",
    message: "",
  })

  const changeText = (text) => {
    setFormDetails((oldState) => {
      return {
        ...oldState,
        [text.target.name]: text.target.value,
      }
    })
  }

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    if (
      formDetails.userName == "" ||
      formDetails.emailId == "" ||
      formDetails.subject == "" ||
      formDetails.message == ""
    ) {
      settext("Please fill in the form completely before sending!!")
      return setOpen(true)
    } else {
      setDisable(true)
      settext("Form is submitting... Please wait..")
      setOpen(true)
      console.log("before submission")
      axios
        .post("http://13.126.94.16:8080/contactUs", {
          name: formDetails.userName,
          email: formDetails.emailId,
          subject: formDetails.subject,
          message: formDetails.message,
        })
        .then((response) => {
          console.log(response.data)
          settext(response.data.message)
          setFormDetails({
            userName: "",
            emailId: "",
            subject: "",
            message: "",
          })
          setTimeout(() => {
            setOpen(false)
          }, 3000)
          setDisable(false)
        })
        .catch((error) => {
          console.log(error)
          settext(error)
          setDisable(false)
        })
    }
  }

  return (
    <div className="expert-container" id="CONTACT-US">
      <AppHeader
        image={contactUs}
        title="Contact Us"
        subTitle="Drop down your info below to reach us! "
      />
      <div className="expert-contact">
        <motion.div
          className="expert-contact-details"
          ref={ref}
          variants={cardComponent}
          initial="hidden"
          animate={controls}
        >
          <div className="contact-details-content">
            <div className="contact-details-icons">
              <TiLocation size={30} color="white" />
            </div>
            <div className="address">
              <p className="contact-subheading">Location</p>
              <p className="contact-item ">
                Plot No 5 & 6, Road No 12 F, New Maruthinagar, Kothapet,
                Dilsukhnagar, Hyderabad 500 060, INDIA
              </p>
            </div>
          </div>
          <div className="contact-details-content">
            <div className="contact-details-icons">
              <TfiEmail size={30} color="white" />
            </div>
            <div>
              <p className="contact-subheading">Email</p>
              <p className="contact-item">info@nirveshenterprises.com</p>
            </div>
          </div>
          <div className="contact-details-content">
            <div className="contact-details-icons">
              <RiContactsFill size={30} color="white" />
            </div>
            <div>
              <p className="contact-subheading">Call</p>
              <p className="contact-item">91-8019604914, 91-40-35578022</p>
            </div>
          </div>

          <iframe
            class="gmap_iframe"
            className="google-map-image"
            src="https://maps.google.com/maps?width=400&amp;height=400&amp;hl=en&amp;q=nirvesh enterprises&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </motion.div>
        <motion.div
          className="expert-contact-form"
          variants={cardComponent}
          initial="hidden"
          animate={controls}
        >
          <p className="contact-form-heading">
            Submit Your queries or suggestions
          </p>
          <div className="expert-contact-content">
            <form ref={form} onSubmit={sendEmail}>
              <label className="input-text-label">Name</label>
              <input
                className="input-text"
                type="text"
                name="userName"
                onChange={changeText}
                value={formDetails.userName}
              />
              <label className="input-text-label">Email</label>
              <input
                className="input-text"
                type="email"
                name="emailId"
                onChange={changeText}
                value={formDetails.emailId}
              />
              <label className="input-text-label">Subject</label>
              <input
                className="input-text"
                type="text"
                name="subject"
                onChange={changeText}
                value={formDetails.subject}
              />
              <label className="input-text-label">Message</label>
              <textarea
                className="message-text-area"
                name="message"
                onChange={changeText}
                value={formDetails.message}
              />
              <input
                type="submit"
                disabled={disable}
                value="Send"
                className="app-button form-submit-button"
              />
            </form>
          </div>
        </motion.div>
      </div>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Message Submission"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

export default ContactOurExperts
