import React from "react";

function UserText({ item }) {
  return (
    <div className="chat-mini-user-container" key={item.id}>
      <p className="user-message">{item.text}</p>
    </div>
  );
}

export default UserText;
