import "./App.css"
import HomePage from "./Components/HomePage"
import ModalContext from "./Contexts/ModalContext/SubscriptionModalContext"
import { useState } from "react"

import { BrowserRouter as Router } from "react-router-dom"
import LoaderPage from "./Components/LoaderPage/LoaderPage"

function App() {
  const [openModal, setOpenModal] = useState(false)
  const [loader, setLoader] = useState(true)

  setTimeout(() => {
    setLoader(false)
  }, 4000)

  if (loader) {
    return (
      <div className="loader-page">
        <LoaderPage />
      </div>
    )
  } else {
    return (
      <Router>
        <ModalContext.Provider value={{ openModal, setOpenModal }}>
          <div className="App">
            <HomePage />
          </div>
        </ModalContext.Provider>
      </Router>
    )
  }
}

export default App
