import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Alert from "react-bootstrap/Alert"

import JobDescription from "./JobDescription"

import { MdClose } from "react-icons/md"
import axios from "axios"

function JobDescriptionCard({ show, item, handleClose }) {
  const closeModal = () => handleClose()

  const [submit, setSubmit] = useState({
    isOpen: false,
    text: "",
  })
  const [file, setFile] = useState("")

  const handlePdf = (e) => {
    setFile(e.target.files[0])
  }

  const SubmitApplication = () => {
    let formData = new FormData()
    formData.append("jobPosition", item.position)
    formData.append("experience", item.experience)
    formData.append("department", item.department)
    formData.append("jobDescription", item.description)
    formData.append("location", item.location)
    formData.append("published", item.datePublished)
    formData.append("application", file)
    if (!file) {
      setSubmit({
        isOpen: true,
        text: "Please attach the file to submit and try again",
      })
      setTimeout(() => {
        setSubmit({
          isOpen: false,
          text: "",
        })
      }, 2000)
      return
    }
    setSubmit({
      isOpen: true,
      text: "Appplication is processing...",
    })
    axios
      .post("http://13.126.94.16:8080/submitapplication", formData)
      .then((response) => {
        setSubmit({
          isOpen: true,
          text: response.data.message,
        })
        setTimeout(() => {
          setSubmit({
            isOpen: false,
            text: "",
          })
        }, 2000)
      })
      .catch((error) => {
        setSubmit({
          isOpen: true,
          text: "Application failed to submit, Please try again after sometime",
        })
        setTimeout(() => {
          setSubmit({
            isOpen: false,
            text: "",
          })
        }, 2000)
      })
  }
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <div className="job-description-container">
          <MdClose onClick={closeModal} className="close-modal-button" />
          <p className="job-application-title">{item.position}</p>
          <div className="job-application-container">
            <JobDescription
              title="Experience"
              description={item.experience}
              experience={true}
            />
            <JobDescription title="Department" description={item.department} />
            <JobDescription
              title="Skills"
              description={item.skills}
              skills={true}
            />
            <JobDescription
              title="Job Description"
              description={item.description}
            />
            <JobDescription title="Location" description={item.location} />
            <JobDescription
              title="Published On"
              description={item.datePublished}
            />
          </div>
          <div className="job-desciprtion-file-container">
            <label htmlFor="cv">
              Upload your resume along with motivation letter in a pdf file:
            </label>
            <input
              type="file"
              id="cv"
              name="cv"
              onChange={handlePdf}
              accept=".pdf"
            />
          </div>
          <div onClick={SubmitApplication} className="job-button-container">
            <button disabled={submit.isOpen} className="job-application-button">
              Apply
            </button>
          </div>
          {submit.isOpen && (
            <Alert variant="info" style={{ textAlign: "center" }}>
              {submit.text}
            </Alert>
          )}
        </div>
      </Modal>
    </>
  )
}

export default JobDescriptionCard
