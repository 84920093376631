import "./index.css"
import Carousel from "react-bootstrap/Carousel"
import colors from "../../DefaultColors"
import innovation from "./Assets/innovativeSolution.png"
import dataTracking from "./Assets/data-tracking.png"
import costEffective from "./Assets/costEffective.png"
import { FaChevronRight } from "react-icons/fa"
import { FaChevronLeft } from "react-icons/fa"

const nextIcon = () => {
  return <FaChevronRight size={36} color={colors.blue} />
}
const prevIcon = () => {
  return <FaChevronLeft size={36} color={colors.blue} />
}

function CarouselHeader() {
  return (
    <Carousel
      nextIcon={nextIcon()}
      prevIcon={prevIcon()}
      slide={false}
      className="carousel-component"
    >
      <Carousel.Item>
        <div
          style={{
            background: "rgb(21,112,173)",
            background:
              "linear-gradient(106.04deg, #003366 5.02%, #4A84C3 109.29%)",
            backgroundColor: colors.blue,
            width: "100%",
            height: "80vh",
          }}
          className="glass-morphic-effect"
        >
          <div className="banner-content">
            <div className="text-content">
              <p className="heading-content">Innovative solution</p>
              <p className="subHeading-content">
                Our multimodal technologically driven system has provided to be
                the base to treatment of a number of disorders related to brain
                health which in general are not so feasible. With a vision to
                replace and out-do the current market stature we have our own
                set of experts who have come onboard with us in bringing and
                providing the most reliable product ready to be in effective use
                no sooner.
              </p>
            </div>
            <img
              src={innovation}
              alt="bannerImage"
              className="costEffective-style innovation-image2"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          style={{
            marginTop: "-20px",
          }}
          className="carousel-main-container glass-morphic-effect"
        >
          <div className="banner-content">
            <div className="text-content">
              <p className="heading-content">Cost Effective Treatment</p>
              <p className="subHeading-content">
                The rising rates have become a hinderance in getting medical
                treatments, to which we have taken immense considerations
                thereby bringing products that are as accurate with toned down
                charges. While the examination and test results would be
                remaining the same it would come at a more consumer beneficiary
                prices thus making our products a leading advancement in the
                field of neurostimulation.
              </p>
            </div>
            <img
              src={costEffective}
              alt="bannerImage"
              className="costEffective-style cost-effective-image"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          style={{
            backgroundImage:
              "linear-gradient(to right, #243949 0%, #517fa4 100%)",
            width: "100%",
            height: "80vh",
          }}
          className="glass-morphic-effect"
        >
          <div className="banner-content">
            <div className="text-content">
              <p className="heading-content">Precise Data Tracking</p>
              <p className="subHeading-content">
                What makes our services more interesting is the concept of
                getting these results at a convenient rate having diagnosed from
                our clinical experts. Software infused with advanced base tech
                system working with precise algorithm and frequency having
                audio-fuse incrementation will help in providing much needed
                accuracy to test results. It will come at a customer paced level
                with data tracking becoming simple and comprehensible to
                everyone.
              </p>
            </div>
            <img
              src={dataTracking}
              alt="bannerImage"
              className="costEffective-style"
            />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  )
}

export default CarouselHeader
