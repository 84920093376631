import React, { useEffect } from "react"
import { Typography } from "@mui/material"
import "./index.css"

import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import colors from "../../DefaultColors"
import UniqueCards from "../../ReusableComponents/UniqueCards"
import AppTitle from "../../ReusableComponents/AppTitle"

const data = [
  {
    id: "01",
    title: "Integrated intelligent sensors",
    descrption:
      "Devices have sensors similar to EEG and fNIRS which work in tandem by sensing brain waves, and cerebral blood flow that represent the current brain health of patients at affordable rates than standard brain health diagnosis.",
  },
  {
    id: "02",
    title: "Refining doctor-patient relations",
    descrption:
      "Test results followed with routine consultations from doctors and healthcare professionals thereby generating Real-World Evidence (RWE) for precise Medi-care and enhanced treatments.",
  },
  {
    id: "03",
    title: "Real time data driven personalized cognitive management",
    descrption:
      "Seamless integration of hardware wearables and software platform",
  },
]
const contentContainer = {
  hidden: {
    opacity: 0,
    scale: 0.7,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "tween",
      ease: "easeInOut",
      duration: 1,
    },
  },
}

function Unique() {
  const controls = useAnimation()
  const { ref, inView } = useInView({
    threshold: 0.2,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <div ref={ref} className="unique-container">
      <motion.div
        variants={contentContainer}
        initial="hidden"
        animate={controls}
        className="unique-content-container"
      >
        <AppTitle title="We Are Unique" />
        <div className="unique-main-cards">
          <UniqueCards
            number={data[0].id}
            title={data[0].title}
            description={data[0].descrption}
          />

          <UniqueCards
            number={data[1].id}
            title={data[1].title}
            description={data[1].descrption}
            special={true}
          />
          <UniqueCards
            number={data[2].id}
            title={data[2].title}
            description={data[2].descrption}
          />
        </div>
      </motion.div>
    </div>
  )
}

export default Unique
