import React from "react"
import "./OurInnovation.css"
import imageglobe from "./Assets/globe.jpg"

import innovationBackground from "./Assets/innovation-background.jpg"

import AppHeader from "../../ReusableComponents/AppHeader"
import { Container } from "@mui/system"

import research from "./Assets/research2.jpg"
import patient from "./Assets/patient.jpg"
import success from "./Assets/success.jpg"

function OurInnovation() {
  return (
    <div className="product-container">
      <AppHeader
        image={innovationBackground}
        title="Product"
        subTitle="We function to connect on a Neurological level."
      />
      <div className="product-innerContainer">
        <p className="product-mainParagraph">
          Since the very beginning of our vision to bring Nirvesh into picture
          we had the concept of delivering neuro-health related treatment at
          economical rates to each and every individual in need. The idea was to
          pool in technologies of various machines into one and give out data of
          the brain by providing the exact same method of analysing and
          collecting data through the use of waves and electricity. Though the
          machines of fNIRS and MRI scans are big enough, they have their own
          set of limitations and the preceding technology has been looking out
          for chances to improvise these limitations. Keeping in mind the
          accuracy levels that are not exactly enhanced and a hundred percent
          precise when it comes to the deformities in the brain, it can give a
          vague picture for the medical practitioner to further examine and tell
          out the problem, but letting the machine do it all by itself? this is
          what we desire to achieve.
        </p>
        <div className="product-content">
          <p className="product-paragraph">
            The products Synapsil and Neuroxite that we are currently working
            on and supposedly planning to launch pretty soon uses the
            combination of various technologies present in the machines of EEGs
            and fNIRS. This technology uses multimodal level assessment to
            covert the data collected from them to analyse the mental stature of
            our patients. It is currently under the R&D stage and plans on
            offering a beneficiary result along with the various measures that
            should be taken, and in return one also gets the benefits of
            consultancy from our medical experts.
            <br />
            Though the function of the brain happens normally despite it being
            so complex but the symptoms of any kind of disorder changes it
            anomaly. Quite sadly, the after affects of post brain injury causes
            severe impact on the head which leads to chemical imbalances,
            disorders and in turn trauma.
          </p>
          <img src={research} alt="innovation-img" className="product-image" />
        </div>
        <div className="product-content">
          <img
            src={success}
            alt="innovation-img"
            className="product-image show-image"
          />
          <p className="product-paragraph">
            Seeing the necessity of upgradation in the mental conditions of
            people undergoing such incident we are determined to cross the
            dilemma and fear of unable to recover after brain injuries or any
            such disorders. Our technology allows precise measurement of
            electrical and neural activity in the brain and lets medical
            professionals know the current condition. Not just that it also
            helps in improving brain health and recovering from chronic
            paralysis and bettering the cognitive health of the person. 
            {/* This exceptional model is a neuro stimulating one and has a targeted
            brain health status classification algorithm with an accuracy of
            more than 95%. */}
            <br />
            Working on the concept of Brain Cognitive Interface (BCI) in medical fields we are keen on
            launching these devices that aid in diagnosis, assist and prevent
            any further complications in the brain and give necessary intervention
            to ameliorate present state.
          </p>
          <img
            src={success}
            alt="innovation-img-hidden"
            className="product-image hidden-image"
          />
        </div>
      </div>
    </div>
  )
}

export default OurInnovation
