import React, { useState, useEffect } from "react"
import { Outlet, Link } from "react-router-dom"

import { RxCross2 } from "react-icons/rx"
import botImage from "./ChatBotComp/Assets/eyes-open.png"
import botImageClose from "./ChatBotComp/Assets/eyes-close.png"
import CookieConsent from "react-cookie-consent"
import colors from "../DefaultColors"
import ChatBot from "./ChatBotComp/ChatBot"

import Navbar2 from "./NavbarComp/Navbar2"
import Footer from "./FooterComp/Footer"

function PageLayout() {
  const [showBot, setShowBot] = useState(false)
  const [botMessage, setBotMessage] = useState(true)
  const [chatBotOpen, setChatBotOpen] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowBot(true)
      } else {
        setShowBot(false)
      }
    })
  }, [])

  const handleOpenChatbot = (e) => {
    e.stopPropagation()
    setChatBotOpen(!chatBotOpen)
  }

  const handleChatMessage = () => {
    setBotMessage(false)
  }
  return (
    <>
      <Navbar2 />
      <Outlet />
      <Footer />
      <CookieConsent
        debug={true}
        expires={30}
        style={{
          backgroundColor: colors.primaryWhite,
          color: colors.dark,
          padding: "0px 60px 0px 20px",
        }}
        buttonStyle={{
          backgroundColor: colors.blue,
          color: colors.primaryWhite,
          fontSize: "1.1rem",
        }}
      >
        By clicking "I Understand", You accept our cookies and{" "}
        <Link to="privacypolicy">privacy policy</Link> of our website
      </CookieConsent>

      {showBot && (
        <img
          src={botImage}
          alt="botImage"
          className="bot-image-open"
          onClick={handleOpenChatbot}
        />
      )}
      {chatBotOpen && showBot && <ChatBot />}
      {showBot && (
        <img
          src={botImageClose}
          alt="botImage"
          className="bot-image-close"
          onClick={handleOpenChatbot}
        />
      )}
      {chatBotOpen && showBot && <ChatBot />}
      {showBot && botMessage && !chatBotOpen && (
        <div className="chat-bot-message">
          <RxCross2
            className="chat-message-close"
            onClick={handleChatMessage}
          />
          <p>
            Hello welcome,
            <br />
            I'm Veda a chatbot, Let me know if you need any help!!
          </p>
        </div>
      )}
    </>
  )
}

export default PageLayout
