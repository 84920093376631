import React, { useState } from "react"
import "./index.css"

function AppButtonRound({
  text,
  bgColor,
  border,
  width,
  textColor,
  afterClick,
  type,
}) {
  const [toggle, setToggle] = useState(false)

  const toggleClasses = () => {
    setToggle(!toggle)
  }

  const handleClick = (e) => {
    e.preventDefault()
    afterClick && afterClick()
  }
  return (
    <button
      className="app-button-round"
      type={type}
      onMouseEnter={toggleClasses}
      onMouseLeave={toggleClasses}
      onClick={handleClick}
      style={{
        backgroundColor: !toggle ? bgColor : "transparent",
        borderColor: border,
        color: toggle && border,
        width: width,
      }}
    >
      {text}
    </button>
  )
}

export default AppButtonRound
