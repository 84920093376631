import React from "react";
import "./Newsletter.css";

import AppHeader from "../../ReusableComponents/AppHeader";

function Newsletter() {
  return (
    <div className="newsletter-container">
      <AppHeader
        image="#"
        title="News Letter"
        subTitle="lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
      />
    </div>
  );
}

export default Newsletter;
