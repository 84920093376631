import image from "./Assests/team-3.jpg"
import Anvesh from "./Assests/Anvesh.jpg"
import Prabitha from "./Assests/Prabitha.png"
import Matthew from "./Assests/Matthew.png"
import Pranjali from "./Assests/Pranjali.jpg"
import Pradeep from "./Assests/Pradeep.png"
import Soyal from "./Assests/Soyal.jpg"
import Guna from "./Assests/Guna.jpg"
import Satya from "./Assests/satya.jpg"
import Jamuna from "./Assests/Jamuna.png"
import Amit from "./Assests/Amit.png"
import Dhawal from "./Assests/Dhawal.png"
import Indranil from "./Assests/Indranil.jpg"
import Girdhar from "./Assests/Girdhar.png"
import nitish from "./Assests/nitish.jpeg"
import Sujeer from "./Assests/Sujeer.jpg"

const FoundersData = [
  {
    id: "1",
    profileName: "Dr. Anvesh Jallapally, PhD ",
    profileDesign: "Founder & Chief Executive Officer",
    description: "Business Operations and  Product Development",
    url: Anvesh,
    linkedin: "https://www.linkedin.com/in/jallapallyanvesh/",
  },
  {
    id: "2",
    profileName: "Akhila Reddy Lingannagari",
    profileDesign: "Co-Founder & Chief Technical Officer",
    description: "Technical Management Head",
    url: image,
    linkedin: "https://www.linkedin.com/in/akhilareddylingannagari/",
  },
  {
    id: "3",
    profileName: "Dr.Prabitha Urwyler, PhD",
    profileDesign: "Co-Founder & Chief Scientific Officer",
    description: "Product Technical Validation",
    url: Prabitha,
    linkedin: "https://www.linkedin.com/in/prabithaurwyler/",
  },
  {
    id: "4",
    profileName: "Matthew Barras",
    profileDesign: "Co-Founder & Chief Product Officer",
    description: "Product design & Product Architecture",
    url: Matthew,
    linkedin: "",
  },
]

const TeamData = [
  // {
  //   id: "1",
  //   profileName: "Dr. Pranjali Gajbhiye, PhD",
  //   profileDesign: "Digital Signal Process Engineer",
  //   description: "Algorithm design and Validation",
  //   url: Pranjali,
  //   linkedin: "https://www.linkedin.com/in/pranjali-gajbhiye-0b8003133/",
  // },
  // {
  //   id: "2",
  //   profileName: "Dr. Pradeep Chouskey , MD",
  //   profileDesign: "Medical Affairs, Neurosurgeon",
  //   description: "Clinical Validation",
  //   url: Pradeep,
  //   linkedin: "",
  // },
  {
    id:"3",
    profileName: "Soyal Kumar",
    profileDesign: "Quality Manager",
    description: "Quality & Regulatory Assurance",
    url: Soyal,
    linkedin: "https://www.linkedin.com/in/soyal-kumar-kp-4ab035128/",
  },
  {
    id: "4",
    profileName: "Guna Deepak",
    profileDesign: "Product Design Engineer",
    description: "Product Design & Development",
    url: Guna,
    linkedin: "https://www.linkedin.com/in/kunuthuru-deepak-742213147/",
  },
  {
    id: "5",
    profileName: "Satya Ganesh",
    profileDesign: "Software Developer",
    description: "Mobile and Web Applications",
    url: Satya,
    linkedin: "https://www.linkedin.com/in/satya-ganesh-ch-377121240/",
  },
  {
    id: "6",
    profileName: "Esha Choudhary",
    profileDesign: "Digital Signal Processing Engineer",
    description: "Algorithm design and Validation",
    url: nitish,
    linkedin: "https://www.linkedin.com/in/eshachoudhary06/",
  },
]
const AdvisoryBoardData = [
  {
    id: "1",
    profileName: "Prof. Dr. Jamuna Rajeswaran",
    profileDesign:"Scientific Advisor, Clinical Psychologist, NIMHANS, Bangalore",
    description: "Product Validation",
    url: Jamuna,
    linkedin: "",
  },
  {
    id: "2",
    profileName: "Prof. Dr. Amit Aggarwal",
    profileDesign: "Scientific Advisor, Trauma Surgeon, AIIMS, Bhopal",
    description: "Clinical Studies and Product Validation",
    url: Amit,
    linkedin: "",
  },
  {
    id: "3",
    profileName: "Prof. Dr. Dhaval Shukla",
    profileDesign: "Scientific Advisor, Trauma surgeon, NIMHANS, Bangalore",
    description: "Clinical Studies and Product Validation",
    url: Dhawal,
    linkedin: "",
  },
  {
    id: "4",
    profileName: "Dr. Sujeer Basha",
    profileDesign: "Clinical Advisor, Physiotherapist",
    description: "Clinical Trial Operations",
    url: Sujeer,
    linkedin: "",
  },
  {
    id: "5",
    profileName: "Mr. Girdhar Balwani",
    profileDesign: "Business Advisor",
    description: "Business Operations",
    url: Girdhar,
    linkedin: "",
  },
  {
    id: "6",
    profileName: "Mr. Indranil Majumdar",
    profileDesign: "Technical Advisor",
    description: "Product Design, Architecture & Validation",
    url: Indranil,
    linkedin: "",
  },
]

export { FoundersData, TeamData, AdvisoryBoardData }
