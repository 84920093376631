import React from "react";

function BotText({ item, selection }) {
  const handleClick = (button) => {
    selection(button);
  };
  return (
    <div className="chat-mini-bot-container" key={item.id}>
      <p className="bot-message">{item.text}</p>
      <div className="bot-options-container">
        {item.options.map((button) => {
          return (
            <p
              onClick={() => handleClick(button)}
              className="bot-option-button"
            >
              {button.option}{" "}
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default BotText;
