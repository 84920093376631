const ChatBotResponses = [
  {
    id: "1",
    user: "bot",
    text: "Hello There, Let me know what u looking for. Kindly select any option from the below",
    options: [
      {
        id: "option1",
        option: "I need assistance with the device / hardware",
      },
      {
        id: "option2",
        option: "I would like to raise a complaint against the service",
      },
      {
        id: "option3",
        option: "I want to talk to the customer service people regarding my purchase",
      },
    ],
  },

  {
    id: "option1",
    user: "bot",
    text: "Let me know the issue",
    options: [
      {
        id: "option1",
        option: "They are damaged",
      },
      {
        id: "option2",
        option: "Not working properly",
      },
      {
        id: "option3",
        option: "I would like to buy new one",
      },
      {
        id: "option4",
        option: "Data is not synced problem",
      },
    ],
  },

  {
    id: "option2",
    user: "bot",
    text: "We are very sorry for you the inconvinience caused for you. Kindly let me know more",
    options: [
      {
        id: "option1",
        option: "I'm unhappy with the service",
      },
      {
        id: "option2",
        option: "I would like to talk to the customer care",
      },
      {
        id: "option3",
        option: "Would like to have assistance regarding therapy",
      },
    ],
  },
  {
    id: "option3",
    user: "bot",
    text: "We are always here to help",
    options: [
      {
        id: "option1",
        option: "Would like to have invoice for our purchase",
      },
      {
        id: "option2",
        option: "Write a mail to the customer service",
      },
      {
        id: "option3",
        option: "Would like to more about reimbursment policy",
      },
    ],
  },
];

export default ChatBotResponses;
