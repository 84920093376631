import React from "react"

import AboutUs from "./AboutUsComp/AboutUs"
import CarouselHeader from "./CarouselComp/CarouselHeader"
import WeAreUnique from "./uniqueComp/WeAreUnique"
import MobileSubscription from "./MobileSubsciptionsComp/MobileSubscription"
// import OffersAndSubscription from "./MobileSubsciptionsComp/OffersAndSubscription"

function ActualHomePage() {
  return (
    <div>
      <CarouselHeader />
      <AboutUs />
      <WeAreUnique />
      <MobileSubscription />
      {/* <OffersAndSubscription /> */}
    </div>
  )
}

export default ActualHomePage
