import React from "react"
import "./OurVision.css"
import image1 from "./Assets/brainImage2.jpg"

import Container from "@mui/material/Container"

import visionBackground from "./Assets/blindingLights.jpg"

import AppHeader from "../../ReusableComponents/AppHeader"

function OurVision() {
  return (
    <div className="vision-container">
      <AppHeader
        image={visionBackground}
        title="Our Vision"
        subTitle="To advance diagnosis and treatment of neurodegenerative disorders for a healthier tomorrow."
      />
      <Container>
        <div className="vision-lastNote">
          <span className="vision-first-letter">We</span> are a team of highly
          qualified professionals with an experience of 45 years' in the
          revolutionary field of medical sciences, growing with the idea of
          adopting neuro sciences, biomedical sciences and neuropsychiatry and
          basing our products on their diagnosis with the central motive
          revolving around cognitive health.
        </div>
        <div className="vision-text-content">
          <p className="vision-brief">
            Neurosensing is a vast and flourishing field where with the
            advancement in stimulation and discovery of those nerves a variety
            of diseases or disorders can be treated or eased. It collectively
            represents a range of functions that are revived or conditioned
            through stimulation while helping us understand the cognitive health
            of a person. Cognitive health, the ability of the brain to retrieve
            complex information and perform those functions. It also signifies
            the capability of the brain to learn and remember different things
            and execute them using reasoning. With the rise in technology,
            recognition to neurological disabilities have come into picture, and
            development of advanced and excellent neuromodulation and
            neurostimulation techniques that use minimally invasive techniques
            to help individuals is driving the growth of Neurotech Industry.
            Neurotechnology is a robust market having a growth rate of 12% to
            more in the near future with neurostimulation being the largest and
            the most thriving sector.
          </p>
        </div>
        {/* <img src={image1} className="vision-image" /> */}
        <div className="vision-text-content">
          <p className="vision-brief">
            The key for any company to thrive is in their vision, their idea and
            their beliefs in achieving the very thing they have set their eyes
            on, thus, Nirveshs' group of experts come with a visionary idea to
            tackle the problems revolving around neurology and neurosensing. We
            use a combination of science and technology to study post operative
            brain health management system, where our products use the concept
            of EEG (Electroencephalogram) and fNIRS (Functional near-infrared
            spectroscopy) to give out precise results of the activities in the
            brain. EEG's monitor a number of conditions using electric sensors
            that helps identify cause of certain symptoms such as epilepsy,
            seizers, memory problems, dementia, head injuries among others while
            fNIRS use near-infrared spectroscopy for the purpose of functional
            neuroimaging to measure the oxygen levels in the brain. Using a
            combination of the two we've created a product that performs similar
            functions at a much reasonable price, which also happens to be
            efficient, affordable and portable unlike the massive machines.
          </p>
        </div>
        <div className="vision-text-content">
          <p className="vision-brief">
            Our approach to the global market would indulge introducing
            multimodal level assessment which will help us accomplish a product
            that is beneficiary and cost effective in nature, replacing the
            current neurosensing machines to something more technologically
            driven and dynamic. We see ourselves to be amongst the top companies
            in the world to provide effective neurostimulatory results and to
            help make the world a more neurally healthier environment.
          </p>
        </div>
        <div className="vision-text-content">
          <p className="vision-lastNote">
            The future lies in the development of systems that can monitor,
            interpret and read out the neurological state of an individual at a
            much efficient rate. It is to believed that with the upcoming
            improvement in the sci-tech industry there are chances for recovery
            for patients suffering from severe brain injury and we aim to
            achieve exactly that.
          </p>
        </div>
      </Container>
    </div>
  )
}

export default OurVision
